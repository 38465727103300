<template>
    <div>
         <BasicFilter :filterItem="filterItem" :filterObject="filterObject" :dropdown="dropdown" :hideOperators="hideOperators"></BasicFilter>
    </div>
</template>

<script setup>
    import BasicFilter from './components.FilterEditor.Basic.vue';

    const props = defineProps({
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null 
    });
</script>
 